import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import logo from "../../assets/identity/get-started-fpl-logo.svg";
import { Button, CoolFeatureList } from "../../components";
import { Helmet } from "react-helmet";
import { GET_STARTED_PAGES } from "../../constants/auth-pages.constants";
import { ROUTES } from "../../config/routes";
import { FormContainer } from "./components";
import { bgColor } from "../../config/theme";
import { mq, primaryGradient } from "../../config/styles";

import chart from "../../assets/cool-feature/chart.svg";
import mostHurtful from "../../assets/cool-feature/most-hurtful.svg";
import tshirt from "../../assets/cool-feature/tshirt.svg";

const features = [
  {
    title: "Discover FPL highlights",
    img: chart,
    description:
      "Explore 20+ exciting mini-league highlights for an edge in your fantasy football journey.",
  },
  {
    title: "Fixtures Player Ownership",
    img: tshirt,
    description:
      "See which managers in your mini leagues own or have captained players across all fixtures.",
  },
  {
    title: "Most Hurtful Players",
    img: mostHurtful,
    description:
      "Reveals the most hurtful players in your mini-league that others own but you don't!",
  },
];

/** Renders the Get started page for user that are not signed in and came from a shared link */
export const GetStartedPage = () => {
  return (
    <FormContainer>
      <Helmet>
        <title>{GET_STARTED_PAGES.META.TITLE}</title>
      </Helmet>

      <Stack css={rootStyles}>
        <Stack css={mainContainerStyles}>
          <img src={logo} css={imgStyles} />
          <Typography css={titleStyles}>{GET_STARTED_PAGES.TITLE}</Typography>

          <CoolFeatureList featuresList={features} />
          <Typography css={subTitleStyles}>
            <span css={textOverLay}>{GET_STARTED_PAGES.SUBTITLE}</span>
            <span>{GET_STARTED_PAGES.EMOJI}</span>
          </Typography>
        </Stack>

        <Button to={ROUTES.REGISTER_PAGE} button="gradient">
          {GET_STARTED_PAGES.BTN}
        </Button>
      </Stack>
    </FormContainer>
  );
};

const rootStyles = css`
  height: 100vh;
  width: 100%;
  padding: 2rem;
  background-color: ${bgColor};
  gap: 1rem;

  ${mq["md"]} {
    padding: 0;
    height: auto;
    background-color: transparent;
    gap: 1rem;
  }
`;

const imgStyles = css`
  width: 6rem;
`;

const mainContainerStyles = css`
  flex: 1;
`;

const titleStyles = css`
  font-weight: 600;
  font-size: 2.5rem;
`;

const subTitleStyles = css`
  font-weight: 700;
  font-size: 1.3rem;
`;

const textOverLay = css`
  ${primaryGradient}
  background-clip: text;
  color: transparent;
`;
