import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppSimpleTable, AppSimpleTableSkeleton } from "../../../components";
import { IEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import {
  eplPlayerHistoryPast,
  eplPlayerSummaryHistoryColumns,
} from "../../../utils/tableColumns/eplPLayerSummaryColumns";
import { GridEventListener } from "@mui/x-data-grid";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";
import { PATH_PARAMS, QUERY_PARAMS } from "../../../config/routes";

interface IEplPlayerSummaryHistoryTable {
  historyRows: IEplPlayerSummary["summary"]["history"] | undefined;
  historyPastRows: IEplPlayerSummary["summary"]["history_past"] | undefined;
}

/**
 * EplPlayerHistoryTable Component Displays current and previous season stats for a player.
 */
export const EplPlayerSummaryHistoryTable = (
  props: IEplPlayerSummaryHistoryTable
) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getFixtureResultsOverviewPath } = useGetUrl();

  const handleTableCellClick: GridEventListener<"cellClick"> = (params) => {
    const row: IEplPlayerSummary["summary"]["history"][0] = params.row;
    const field =
      params.field as keyof IEplPlayerSummary["summary"]["history"][0];

    if (field === "fixture") {
      const tempSearchParams: { [key: string]: string } = {};
      searchParams.forEach((value, key) => {
        tempSearchParams[key] = value;
      });
      navigate({
        pathname: getFixtureResultsOverviewPath(row.fixture).split("?")[0],
        search: createSearchParams({
          ...tempSearchParams,
          [PATH_PARAMS.SELECTED_GAME_WEEK]: String(row.gw),
          [QUERY_PARAMS.FUTURE_GAME_WEEK]: String(row.gw),
        }).toString(),
      });
    }
  };

  return (
    <>
      <Stack paddingInline={"1rem"}>
        <Typography css={titleStyles}>
          {EPL_PLAYER_SUMMARY.TABLE_TITLES.THIS_SEASON}
        </Typography>
        {props.historyRows ? (
          <AppSimpleTable
            getRowId={(row) => row.gw}
            hideFooter
            rowHeight={40}
            columns={eplPlayerSummaryHistoryColumns}
            rows={props.historyRows || []}
            onCellClick={handleTableCellClick}
          />
        ) : (
          <AppSimpleTableSkeleton />
        )}
      </Stack>

      <Stack paddingInline={"1rem"} marginTop={"1.5rem"}>
        <Typography css={titleStyles}>
          {EPL_PLAYER_SUMMARY.TABLE_TITLES.PAST_SEASON}
        </Typography>

        {props.historyPastRows ? (
          <AppSimpleTable
            getRowId={(row) => row.season_name}
            hideFooter
            rowHeight={40}
            columns={eplPlayerHistoryPast}
            rows={props.historyPastRows || []}
          />
        ) : (
          <AppSimpleTableSkeleton />
        )}
      </Stack>
    </>
  );
};

const titleStyles = css`
  font-family: Poppins;
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 700;
`;
