import { css } from "@emotion/react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { tableHeaderBgColor } from "../config/theme";
import { customScrollbar } from "../config/styles";

interface IAppTable extends DataGridProps {}

/**
 * A simple table component using MUI's DataGrid with custom styles.
 * Used in EplPlayerSummary
 */
export const AppSimpleTable = ({ ...props }: IAppTable) => {
  return (
    <DataGrid
      css={dataGridStyles}
      getRowId={(row) => row.entry}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnResize
      disableColumnSelector
      disableMultipleRowSelection
      disableColumnSorting
      {...props}
    />
  );
};

const dataGridStyles = css`
  --separator-color: #2e2f3e;
  border: none;
  color: white;
  font-size: clamp(0.75rem, 1.5vw, 1rem);

  .MuiDataGrid-topContainer {
    border-bottom: 1px solid var(--separator-color) !important;
    &::after {
      display: none;
    }
  }

  /** Table Header */
  .MuiDataGrid-columnHeaders {
    div {
      background-color: ${tableHeaderBgColor} !important;
      border: none;
    }
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-filler {
    background-color: ${tableHeaderBgColor};
    height: auto !important; /* Automatically adjusts the height of the table body to fit the content, ensuring there are no empty rows on any page, including when the last page contains fewer entries. */
    border: none;
    text-align: center;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    border: none;
  }

  .MuiDataGrid-footerContainer {
    border: none !important;
  }

  .MuiDataGrid-filler {
    display: none;
  }

  .MuiDataGrid-row {
    cursor: pointer;
    border-bottom: 1px solid var(--separator-color);
  }

  .MuiDataGrid-scrollbar--vertical {
    ${customScrollbar}
    display: none;
  }

  .MuiDataGrid-scrollbar {
    z-index: 0; // fix scrollbar in mini league tables specifically
  }

  .MuiDataGrid-scrollbar--horizontal {
    ${customScrollbar}
  }

  .MuiDataGrid-main:has(.MuiDataGrid-scrollbar--horizontal)
    > .MuiDataGrid-virtualScroller {
    margin-block: 0.5rem 1rem;
  }

  // Remove border of scrollbar container inside cell
  .MuiDataGrid-scrollbarFiller {
    border: none !important;
  }

  //Footer
  .MuiDataGrid-footerContainer {
    color: white !important;

    & * {
      color: inherit;
    }
  }
`;

import { Stack } from "@mui/system";
import { Skeleton } from "./Skeleton";

interface IAppSimpleTableSkeleton {
  numberOfRows?: number;
}

/** Renders table loading skeleton */
export const AppSimpleTableSkeleton = (props: IAppSimpleTableSkeleton) => {
  return (
    <>
      {Array.from({ length: props.numberOfRows || 9 }, (_, index) => (
        <Stack key={index} css={tableSkeletonStyle}>
          <Skeleton css={rowStyles} />
          <Skeleton css={[midRowStyle, rowStyles]} />
          <Skeleton css={rowStyles} />
        </Stack>
      ))}
    </>
  );
};

const tableSkeletonStyle = css`
  flex-direction: row;
  gap: 1rem;
  height: 2.2rem;
  padding: 0.5rem 0.5rem;
  border-bottom: 2px solid #18182944;

  &:hover {
    background-color: #1a1a2422;
  }
`;

const midRowStyle = css`
  flex-basis: 150%;
`;

const rowStyles = css`
  background-color: #1a1a2488;
`;
