import { useState } from "react";
import { useGetMiniLeagueNewEntriesTable } from "../../utils/hooks/useGetMiniLeagueNewEntriesTable";
import { AppTable, AppTableSkeleton } from "../../components";
import { GridEventListener } from "@mui/x-data-grid";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { tableDividerColor, tableHeaderBgColor } from "../../config/theme";
import { newEntriesColumns } from "../../utils/tableColumns/miniLeagueNewEntriesColumns";
import { Typography } from "@mui/material";
import { MINI_LEAGUE_PAGE } from "../../constants/mini-league.constants";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

/** Renders the mini league new entires page table */
export const MiniLeagueNewEntriesPage = () => {
  const navigate = useNavigateState();
  const { getTeamViewPath } = useGetUrl();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const { newEntries, isLoading } = useGetMiniLeagueNewEntriesTable({
    pageNumber: paginationModel.page + 1,
  });

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(getTeamViewPath(params.row.entry));
  };
  return (
    <div>
      <Helmet>
        <title>{MINI_LEAGUE_PAGE.NEW_ENTIRES.META.TITLE}</title>
      </Helmet>
      <Typography css={tableTitleStyle}>
        {MINI_LEAGUE_PAGE.NEW_ENTIRES.TABLE.TITLE}
      </Typography>
      {newEntries && !isLoading ? (
        <AppTable
          columns={newEntriesColumns}
          rows={newEntries.newEntries}
          dividerColor={tableDividerColor}
          hasNext={newEntries.hasNext}
          onRowClick={handleRowClick}
          getRowId={(row) => row.fplTeamId}
          // Pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          // Page Count
          rowCount={-1} // unknown page count (MUI)
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};

const tableTitleStyle = css`
  background: ${tableHeaderBgColor};
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  font-size: clamp(1.125rem, 5vw, 2rem);
  font-weight: 500;
`;
