import { Typography } from "@mui/material";
import { css } from "@emotion/react";

interface ILandingPageHeader {
  children: React.ReactNode;
}
/**
 * A MUI Typography component with a custom CSS style, designed to be
 * used as a title or heading on the landing page.
 */
export const LandingPageHeader = (props: ILandingPageHeader) => {
  return (
    <Typography css={titleStyles} variant="h1">
      {props.children}
    </Typography>
  );
};

const titleStyles = css`
  font-size: clamp(1.5rem, 3vw, 2.2rem);
  font-weight: 700;
`;
