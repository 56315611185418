import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { primaryGradient } from "../../../config/styles";

/**
 * A component that renders an icon as part of the ExtraFeaturesSection of the landing page.
 */
export const ExtraFeaturesIconContainer = ({ icon }: { icon: string }) => (
  <Stack css={iconsContainerStyles}>
    <img src={icon} />
  </Stack>
);

const iconsContainerStyles = css`
  ${primaryGradient}
  width: clamp(2.5rem, 5vw, 3.5rem);
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 55%;
  }
`;
