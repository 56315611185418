import { css } from "@emotion/react";
import { title } from "../../../config/styles";
import { bgColor, secondaryColor } from "../../../config/theme";
import { getPointsColor } from "../../../utils/general.helpers";
import { Stack, Typography } from "@mui/material";
import { Skeleton } from "../../../components";
import { FPL_CHIPS } from "../../../constants/view-team-pages.constants";
import { Link } from "react-router-dom";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";

interface ITransfersHeaderSection {
  title: string;
  activeChip: string | null | undefined; // null not chip player | undefined is loading
  netPoints: number | undefined;
  transfersCost: number | undefined;
  teamId?: string | number;
}

/**
 * Displays the header section for the league transfers.
 * This component renders the team name and net points for the selected game week.
 * If an active chip is available, it also displays the chip image and title.
 * Used in the TransfersHeaderSection component to show league transfers information.
 */
export const TransfersHeaderSection = (props: ITransfersHeaderSection) => {
  const { getTeamViewPath } = useGetUrl();
  const activeChip = props.activeChip ? FPL_CHIPS[props.activeChip] : null;

  return (
    <Stack>
      <Stack css={transfersSummaryTitleStyles}>
        {props.teamId ? (
          <Link to={getTeamViewPath(props.teamId)}>
            <Typography variant="h1">{props.title}</Typography>
          </Link>
        ) : (
          <Typography variant="h1">{props.title}</Typography>
        )}

        {/** Points can be zero which is a falsy value */}
        {props.netPoints !== undefined && props.transfersCost !== undefined && (
          <Typography css={netPointsStyles}>
            Net Points {`${props.netPoints} - ${props.transfersCost}`} =
            <span style={{ color: getPointsColor(props.netPoints) }}>
              {" "}
              {props.netPoints - props.transfersCost} Pts
            </span>
          </Typography>
        )}
      </Stack>
      {activeChip && (
        <Stack css={activeChipContainerStyles}>
          <img src={activeChip.img} alt="" />
          {activeChip.title}
        </Stack>
      )}
    </Stack>
  );
};

export const TransfersHeaderSectionSkeleton = () => {
  return (
    <Stack css={transfersSummaryTitleStyles}>
      <Skeleton
        component={"div"}
        css={css`
          width: 35%;
          margin-left: auto;
          height: 1.5rem;
          border-radius: 9999px;
          background-color: ${bgColor};
        `}
      />
    </Stack>
  );
};
const transfersSummaryTitleStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    max-width: 50%;
    & > h1 {
      max-width: 100% !important;
    }
  }

  h1 {
    ${title}
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    background: #222232;
    padding: 0.5rem 1rem;
    border-radius: 999rem;

    span {
      color: ${secondaryColor};
    }
  }
`;

const activeChipContainerStyles = css`
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 20px;
  }
`;

const netPointsStyles = css`
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  white-space: nowrap;
`;
