import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import { container, mq } from "../../../config/styles";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { RESET_PASSWORD_PAGE } from "../../../constants/auth-pages.constants";
import { FormContainer, Header } from "../components";
import { AppLogoDivider } from "../../../components";

/** Renders the reset password page  */
export const ResetPasswordPage = () => {
  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{RESET_PASSWORD_PAGE.META.TITLE}</title>
        </Helmet>

        <Header
          title={RESET_PASSWORD_PAGE.TITLE}
          subtitle={RESET_PASSWORD_PAGE.SUBTITLE}
        />

        <AppLogoDivider />

        <ResetPasswordForm />
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;
