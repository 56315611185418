export const HIGHLIGHTS_PATH_NAME = new RegExp(
  /^\/leagues\/\d+\/highlights(\/[a-zA-Z]+)?$/
);

export const SINGLE_HIGHLIGHTS_PATH_NAME = new RegExp(
  /^\/leagues\/\d+\/highlights\/[a-zA-Z]+\/[a-zA-Z]+$/
);
export const ENABLED_MORE_THAN_500 = new RegExp(/^\/leagues\/\d+$/); // /leagues/<digits>
export const VIEW_TEAM_PATH = new RegExp(/^\/leagues\/\d+\/team\/\d+$/);
export const MANAGER_TRANSFERS_PATH = new RegExp(
  /^\/leagues\/\d+\/transfers\/\d+$/
);
