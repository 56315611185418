import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { useQuery } from "react-query";
import { IFplManager } from "../types/types";

export interface IEplPlayerSummary {
  name: string;
  element: number;
  position: "GK" | "DEF" | "MID" | "ATT";
  pick_percentage: string;
  captain_percentage: string;
  owned_by: IFplManager[];
  captained_by: IFplManager[];
  summary: {
    fixtures: {
      id: number;
      code: number;
      team_h: number;
      team_h_score: number | null;
      team_a: number;
      team_a_score: number | null;
      event: number;
      finished: boolean;
      minutes: number;
      provisional_start_time: boolean;
      kickoff_time: string;
      event_name: string;
      is_home: boolean;
      difficulty: number;
      opponent_team_name: string;
      opponent_team_logo: string;
    }[];
    history: {
      gw: number;
      element: number;
      fixture: number;
      opponent_team: number;
      opponent_team_name: string;
      opponent_team_logo: string;
      total_points: number;
      was_home: boolean;
      kickoff_time: string;
      team_h_score: number;
      team_a_score: number;
      round: number;
      minutes: number;
      goals_scored: number;
      assists: number;
      clean_sheets: number;
      goals_conceded: number;
      own_goals: number;
      penalties_saved: number;
      penalties_missed: number;
      yellow_cards: number;
      red_cards: number;
      saves: number;
      bonus: number;
      bps: number;
      influence: string;
      creativity: string;
      threat: string;
      ict_index: string;
      starts: number;
      expected_goals: string;
      expected_assists: string;
      expected_goal_involvements: string;
      expected_goals_conceded: string;
      value: number;
      transfers_balance: number;
      selected: number;
      transfers_in: number;
      transfers_out: number;
    }[];
    history_past: {
      season_name: string;
      element_code: number;
      start_cost: number;
      end_cost: number;
      total_points: number;
      minutes: number;
      goals_scored: number;
      assists: number;
      clean_sheets: number;
      goals_conceded: number;
      own_goals: number;
      penalties_saved: number;
      penalties_missed: number;
      yellow_cards: number;
      red_cards: number;
      saves: number;
      bonus: number;
      bps: number;
      influence: string;
      creativity: string;
      threat: string;
      ict_index: string;
      starts: number;
      expected_goals: string;
      expected_assists: string;
      expected_goal_involvements: string;
      expected_goals_conceded: string;
    }[];
  };
  info: {
    display_name: string;
    value: string | number;
    description?: string;
    index?: string;
  }[];
}

/** useQuery hook for getting player summary */
export const useEplPlayerSummary = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryKey = [
    "useEplPlayerSummary",
    params[PATH_PARAMS.LEAGUE_ID]!,
    params[PATH_PARAMS.TEAM_ID]!,
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
  ];

  const getFixtures = async (queryKey: (string | number | null)[]) => {
    if (queryKey.some((item) => item === undefined || item === null)) return;

    const { data } = await axiosInstance.get<IEplPlayerSummary[]>(
      FPL_CHAMP_ENDPOINTS.EPL_PLAYERS_SUMMARY(
        params[PATH_PARAMS.TEAM_ID]!,
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
        params[PATH_PARAMS.LEAGUE_ID]!
      )
    );

    return data;
  };

  const { data: eplPlayersSummary } = useQuery({
    queryFn: ({ queryKey }) => getFixtures(queryKey),
    queryKey: queryKey,
  });

  return { eplPlayersSummary };
};
