export const TRANSFERS_PAGE_CONTENT = {
  META: {
    TITLE: "Transfers Analysis",
  },
  TITLE: "Transfers Analysis",
  TRANSFERS_CARD: {
    NO_TRANSFERS: "No Transfers this Game week",
    BTN: "Show more",
  },
};
