// import { darkScrollbar } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { mq } from "./styles";

export const navbarHeight = "4.125rem";
export const mainNavigationHeight = "4.875rem";
export const primaryColor = "#033E6A";
export const secondaryColor = "#80CB4F";
export const accentColorRed = "#E2422C";
export const accentColorBlue = "#96EDF4";
export const landingPageGreen = "#00FF87";

// Must use in rgb(${color}) or rgba(${color}, 0.8)
// Enables alpha channels
export const secondaryColorRGB = "128, 203, 79";
export const accentColorBlueRGB = "155, 237, 244";
export const accentColorPurpleRGB = "144, 127, 154";

export const redColor = "#E2422C";
export const redColorHover = "#c63434";
export const white = "#FFF";
export const black = "#1C2321";
export const gray = "#1C2321";
export const darkGray = "#8E8E8E";
export const bgColor = "#181829";
export const bgColorLight = `${bgColor}A6`;
export const secondaryBgColor = "#2C2D3F";
export const bgColorDark = "#1f1f30";
export const tableDividerColor = "#2E2F3E";
export const tableHeaderBgColor = "#2a2b3d";

export const statusColor = {
  error: "#e32737",
};

export const globalStyles = {
  "*::-webkit-scrollbar": {
    width: "0.5em",
    height: "0.4em",
  },
  "*::-webkit-scrollbar-track": {
    background: "transparent",
    border: "1px solid #555",
    borderRadius: "9999px",
    margin: "0.5rem",
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "150ms",
  },
  "*::-webkit-scrollbar-track:hover": {
    background: "#555",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#bdbdbd",
    borderRadius: "9999px",
  },
  [`input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active`]: {
    /** Remove auto fill styling in input fields */
    WebkitTextFillColor: `${white} !important`,
    WebkitBoxShadow: `0 0 0 99999px ${bgColor} inset !important`,
  },
  ul: { margin: 0, padding: 0, listStyleType: "none" },
  li: { margin: 0, padding: 0, listStyleType: "none" },
  a: {
    textDecoration: "none",
    color: "inherit",
  },
};

export const theme = createTheme({
  typography: {
    fontFamily:
      "'Source Sans 3', system-ui, Avenir, Helvetica, Arial, sans-serif",
    h1: {
      fontSize: "50px",
      fontWeight: 700,
    },
    dxl: {
      fontSize: "50px",
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    white: {
      main: white,
    },
    black: {
      main: black,
    },
    gray: {
      main: gray,
    },
    bgColor: {
      main: bgColor,
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: white,
          backgroundColor: bgColor,
          fontSize: "0.875rem",
          [`${mq["md"]}`]: {
            fontSize: "1rem",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          borderRadius: "1.2rem",
          width: "100%",
          height: "100%",
          padding: "1.5rem 0rem",
          backgroundColor: "#181829",
          color: white,
          "&::placeholder": {
            color: "#65656B",
          },
        },
        underline: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "none",
          padding: "1.125rem 1.5rem",
          height: "2.625rem",
          borderRadius: "0.875rem",
        },
        text: {
          textTransform: "none",
          padding: "1.125rem 1.5rem",
          height: "2.625rem",
          borderRadius: "0.875rem",
        },
        outlined: {
          textTransform: "none",
          padding: "1.125rem 1.5rem",
          height: "2.625rem",
          borderRadius: "0.875rem",
        },
      },
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            textTransform: "none",
            background: "linear-gradient(to right, #00ff87, #02efff)",
            color: "#000",
            padding: "1.125rem 1.5rem",
            height: "2.625rem",
            width: "10rem",
            borderRadius: "0.875rem",
            fontWeight: "700",
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          dxl: "h1",
        },
      },
    },
  },
});
