import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useQuery } from "react-query";
import { IEplPlayerTransferInfo } from "./useGetLeagueTransfers";
import { useParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";

export interface IGetManagerTransfersRes {
  entry: number;
  gw_total_points: number;
  fpl_team_name: string;
  fpl_manager_name: string;
  total_net_points: number;
  transfers: {
    [event: string]: {
      active_chip: string | null;
      event_transfers_cost: number;
      net_points: number;
      event: number;
      event_transfers: {
        event: number;
        element_in: number;
        element_in_cost: number;
        element_out: number;
        element_out_cost: number;
        entry: number;
        time: string;
        element_in_info: IEplPlayerTransferInfo;
        element_out_info: IEplPlayerTransferInfo;
      }[];
    };
  };
}

/** useQuery hook for getting fpl manager transfers */
export const useGetManagerTransfers = () => {
  const params = useParams();

  const queryKey = ["useGetManagerTransfers", params[PATH_PARAMS.TEAM_ID]];

  const getTransfers = async (queryKey: (string | number | undefined)[]) => {
    if (queryKey.some((item) => item === undefined || item === null)) return;

    const { data } = await axiosInstance.get<IGetManagerTransfersRes>(
      FPL_CHAMP_ENDPOINTS.GET_MANGER_TRANSFERS_URL(params[PATH_PARAMS.TEAM_ID]!)
    );

    return data;
  };

  const { data: managerTransfers } = useQuery({
    queryFn: ({ queryKey }) => getTransfers(queryKey),
    queryKey: queryKey,
  });

  return { managerTransfers };
};
