import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { useQuery } from "react-query";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";

interface IGetEplPlayerOwnership {
  [key: string]: number;
}

/**
 * Custom hook to fetch the ownership statistics of an
 * EPL player among FPL managers for a specific game week.
 */
export const useGetEplPlayerOwnership = ({
  fplManagers,
  eplPlayerId,
}: {
  fplManagers: number[] | undefined;
  eplPlayerId: number;
}) => {
  const [searchParams] = useSearchParams();

  const queryKey = [
    "useGetEplPlayerOwnership",
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
    fplManagers,
    eplPlayerId,
  ];

  const getEplPlayerOwnership = async (
    queryKey: (string | number | number[] | null | undefined)[]
  ) => {
    if (queryKey.some((item) => item === undefined || item === null)) return;

    const { data } = await axiosInstance.get<IGetEplPlayerOwnership>(
      FPL_CHAMP_ENDPOINTS.EPL_PLAYERS_OWNED_SINCE(
        eplPlayerId,
        fplManagers!.join(","),
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
      )
    );

    return data;
  };

  const { data: eplPlayersSummaryOwnedSince } = useQuery({
    queryFn: ({ queryKey }) => getEplPlayerOwnership(queryKey),
    queryKey: queryKey,
  });

  return { eplPlayersSummaryOwnedSince };
};
