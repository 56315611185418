import { GridColDef } from "@mui/x-data-grid";
import { IMiniLeagueStandingsCaptainHistoryRes } from "../hooks/useGetMiniLeagueStandingsTableHistory";
import {
  AppTableExpandCell,
  AppTableTeamCell,
  AppTooltip,
} from "../../components";
import { IMiniLeagueCaptainsTableRes } from "../hooks/useGetMiniLeagueCaptainTable";
import { IMiniLeagueMostHurtfulRes } from "../hooks/useGetMiniLeagueMostHurtful";
import { IMiniLeagueStanding } from "../hooks/useGetMiniLeagueData";
import { Stack, Typography } from "@mui/material";
import chip from "../../assets/icons/chip.svg";
import { css } from "@emotion/react";
import { APP_TOOLTIP_CONSTANTS } from "../../constants/global.constants";
import { FPL_CHIPS } from "../../constants/view-team-pages.constants";

/**
 * adds the gameWeek captains field in the standing object and return an array of the combined objects
 * used to render the standings table when history is true (past gw for fplMangers)
 */
export const getStandingsTableHistoryRows = (
  standings: IMiniLeagueStanding[],
  miniLeagueCaptainsHistory: IMiniLeagueStandingsCaptainHistoryRes[]
) => {
  const rows = standings.map((item, index) => ({
    ...item,
    gameWeekCaptains: miniLeagueCaptainsHistory[index].gameWeekCaptains,
  }));

  return rows;
};

export const teamColumn: GridColDef<IMiniLeagueStanding> = {
  field: "team",
  headerName: "Team",
  sortable: false,
  flex: 1,
  minWidth: 100,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.entry_name}
      teamName={value.row.player_name}
    />
  ),
};

export const extraColumns: GridColDef<IMiniLeagueStanding>[] = [
  {
    field: "event_total",
    headerName: "GW",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: ({ row }) => {
      if (row.active_chip) {
        return (
          <AppTooltip title={FPL_CHIPS[row.active_chip].title} subTitle="">
            <Stack
              css={css`
                position: relative;
                justify-content: center;
                align-items: center;
              `}
            >
              <img
                src={chip}
                css={css`
                  width: 1.8rem;
                  aspect-ratio: 1/1;
                `}
              />
              <Typography
                css={css`
                  position: absolute;
                  top: 55%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 0.675rem;
                  line-height: 1;
                  margin: 0 !important;
                `}
              >
                {row.event_total}
              </Typography>
            </Stack>
          </AppTooltip>
        );
      }
      return row.event_total;
    },
  },
  {
    field: "total",
    headerName: "Pts",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
];

export const liveGWCaptainPointsColumn: GridColDef<IMiniLeagueStanding> = {
  field: "event",
  headerName: "C.pts",
  align: "center",
  flex: 1,
  headerAlign: "center",
  sortable: false,
  renderCell: (value) => `${value.row.captain_name} (${value.row.captain_pts})`,
};

export const getGameWeekColumn = (
  gameWeekCaptains: IMiniLeagueStandingsCaptainHistoryRes["gameWeekCaptains"]
): GridColDef<IMiniLeagueStandingsCaptainHistoryRes>[] => {
  const captainsArray: GridColDef<IMiniLeagueStandingsCaptainHistoryRes>[] =
    Object.entries(gameWeekCaptains).map(([key, value]) => ({
      field: String(key),
      headerName: `GW ${value.gameWeek}`,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (value) =>
        value.row.gameWeekCaptains && value.row.gameWeekCaptains[key]
          ? `${value.row.gameWeekCaptains[key].captain_name} (${value.row.gameWeekCaptains[key].gw_total_points})`
          : "",
    }));

  return captainsArray;
};

/******* Captains Table Columns *******/

export const countColumn: GridColDef<IMiniLeagueCaptainsTableRes> = {
  field: "count",
  headerName: "Captained by count",
  renderHeader: () => (
    <Typography
      css={css`
        font-size: inherit;
        white-space: normal;
      `}
    >
      Captained by count
      <span
        css={css`
          display: inline;
        `}
      >
        <AppTooltip
          title={APP_TOOLTIP_CONSTANTS.MESSAGES.CAPTAINED_BY_COUNT.TITLE}
          subTitle={APP_TOOLTIP_CONSTANTS.MESSAGES.CAPTAINED_BY_COUNT.SUBTITLE}
        />
      </span>
    </Typography>
  ),
  align: "center",
  headerAlign: "center",
  minWidth: 100,
  flex: 1,
  sortable: false,
};

export const captainColumn: GridColDef<IMiniLeagueCaptainsTableRes> = {
  field: "team",
  headerName: "Name",
  flex: 1,
  minWidth: 80,
  sortable: false,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.name.web_name}
      teamName={value.row.team.name}
    />
  ),
};

export const getCaptainGwPoints = (
  gwHistoryPoints: IMiniLeagueCaptainsTableRes["gwHistoryPoints"]
): GridColDef<IMiniLeagueCaptainsTableRes>[] => {
  return Object.keys(gwHistoryPoints).map((key) => ({
    field: key,
    headerName: `${key} Pts`,
    flex: 1,
    minWidth: 60,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderHeader: () => (
      <span
        css={css`
          opacity: 0.8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: normal;
        `}
      >
        {key} Pts
      </span>
    ),
    renderCell: (value) => (
      <span
        css={css`
          opacity: 0.8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {value.row.gwHistoryPoints && value.row.gwHistoryPoints[key] !== null
          ? value.row.gwHistoryPoints[key]
          : "No Data"}
      </span>
    ),
  }));
};

/******* Most Hurtful Table Columns *******/

export const hurtfulPlayer: GridColDef<IMiniLeagueMostHurtfulRes> = {
  field: "team",
  headerName: "Name",
  flex: 1,
  sortable: false,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.name.web_name}
      teamName={value.row.team.name}
    />
  ),
};

export const arrowColumn: GridColDef = {
  field: "arrow",
  headerName: "Managers that own him",
  flex: 1,
  minWidth: 100,
  align: "center",
  headerAlign: "center",
  sortable: false,
  renderCell: AppTableExpandCell,
};

export const OwnershipColumn: GridColDef<IMiniLeagueMostHurtfulRes> = {
  field: "ownedPercentage",
  headerName: "Ownership in the league",
  renderHeader: () => (
    <Typography
      css={css`
        font-size: inherit;
        white-space: normal;
      `}
    >
      Ownership in the league
      <span>
        <AppTooltip
          title={APP_TOOLTIP_CONSTANTS.MESSAGES.OWNERSHIP_IN_LEAGUE.TITLE}
          subTitle={APP_TOOLTIP_CONSTANTS.MESSAGES.OWNERSHIP_IN_LEAGUE.SUBTITLE}
        />
      </span>
    </Typography>
  ),
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
};
