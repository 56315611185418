import password from "../../../assets/icons/password.svg";
import message from "../../../assets/icons/message.svg";
import { Button, Input, Link, ErrorMessage } from "../../../components";
import { css } from "@emotion/react";
import { Form, Formik } from "formik";
import { SignInFormType, SignInYupSchema } from "../../../utils/validation";
import { SIGN_IN_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { white } from "../../../config/theme";
import { ROUTES } from "../../../config/routes";
import { useUserContext } from "../../../context/UserContext";
import { handleErrors } from "../../../utils/handleErrors";
import { signInSupabase } from "../utils/authHelpers";
import { root } from "../styles";
import { SESSION_STORAGE } from "../../../config/app.config";

/** Renders and handle the sign in form */
export const SignInForm = () => {
  const initialValues = {
    email: "",
    password: "",
    remember: false,
    generalErr: "",
  };

  const { user, setUser, signOutUser } = useUserContext();

  const submitForm = async (
    values: SignInFormType,
    { setSubmitting, setErrors }: any
  ) => {
    try {
      setSubmitting(true);
      if (user && user.isAnonymous) {
        signOutUser();
      }

      const newUser = await signInSupabase(values); // throws error

      // clear session storage from anonyms user if no error
      sessionStorage.removeItem(SESSION_STORAGE.ANON_USER_TEAM_ID);
      setUser(newUser);
    } catch (e) {
      const err = handleErrors(e);
      setErrors({ generalErr: err.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignInYupSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting }) => (
        <Form css={root(isSubmitting)}>
          <Input
            Icon={<img src={message} />}
            placeholder={SIGN_IN_PAGE_CONTENT.EMAIL_FIELD_PLACEHOLDER}
            id="email"
            name="email"
          />
          <Input
            Icon={<img src={password} />}
            placeholder={SIGN_IN_PAGE_CONTENT.PASSWORD_FIELD_PLACEHOLDER}
            type="password"
            id="password"
            name="password"
          />
          <div css={rememberMeAndForgotPass}>
            <Link to={ROUTES.FORGOT_PASSWORD_PAGE} css={forgotPassLink}>
              Forgot Password
            </Link>
          </div>
          <ErrorMessage name="generalErr" />
          <Button button="CTA" type="submit" disabled={isSubmitting}>
            {SIGN_IN_PAGE_CONTENT.CTA_BUTTON_TEXT}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const rememberMeAndForgotPass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const forgotPassLink = css`
  text-decoration: underline;
  color: ${white};
`;
