import { Box, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { bgColorDark, redColor, secondaryColor } from "../../config/theme";
import { PageHeader, Skeleton } from "../../components";
import { TRANSFERS_PAGE_CONTENT } from "../../constants/transfers.constants";
import { useGetManagerTransfers } from "../../utils/hooks/useGetManagerTransfers";
import {
  TransferRow,
  TransfersCardSkeleton,
  TransfersHeaderSection,
} from "./components";

/**
 * Renders a page displaying the transfers made by a manager.
 * It utilizes the `useGetManagerTransfers` hook to fetch transfer data.
 * If no transfer data is available, it returns null.
 * Displays the team and manager names, and lists transfers sorted by game week.
 * Each transfer displays the active chip, net points, and transfer details.
 */
export const ManagerTransfersPage = () => {
  const { managerTransfers } = useGetManagerTransfers();

  return (
    <Stack css={rootStyles}>
      <Stack css={topSectionStyles}>
        <PageHeader
          title={managerTransfers?.fpl_team_name}
          isLoading={!Boolean(managerTransfers)}
        />
        <Typography variant="h2">
          {managerTransfers ? (
            managerTransfers.fpl_manager_name
          ) : (
            <Skeleton width={"50%"} />
          )}
        </Typography>
      </Stack>

      {!managerTransfers && <TransfersCardSkeleton length={10} />}

      {managerTransfers && (
        <Stack css={modalStyles}>
          <Stack css={transfersInOutContainer}>
            <Typography>
              Transfer <span style={{ color: secondaryColor }}>In</span>
            </Typography>
            <Typography>
              Transfer <span style={{ color: redColor }}>Out</span>
            </Typography>
          </Stack>
          {Object.values(managerTransfers.transfers)
            .sort((a, b) => b.event - a.event)
            .map((transfer) => (
              <Stack
                key={transfer.event}
                css={transfersSummaryPlayerContainerStyles}
              >
                <Box css={headerSectionStyles}>
                  <TransfersHeaderSection
                    activeChip={transfer.active_chip}
                    netPoints={transfer.net_points}
                    title={`GW ${transfer.event}`}
                    transfersCost={transfer.event_transfers_cost}
                  />
                </Box>
                <Stack>
                  {transfer.event_transfers.length !== 0 ? (
                    transfer.event_transfers.map((item, i) => (
                      <TransferRow
                        key={i}
                        element_in_info={item.element_in_info}
                        element_out_info={item.element_out_info}
                      />
                    ))
                  ) : (
                    <p css={noTransfersStyles}>
                      {TRANSFERS_PAGE_CONTENT.TRANSFERS_CARD.NO_TRANSFERS}
                    </p>
                  )}
                </Stack>
              </Stack>
            ))}
        </Stack>
      )}
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const modalStyles = css`
  background: ${bgColorDark};
  z-index: -1;

  border-radius: 1.5rem;
  width: 100%;
`;

const transfersInOutContainer = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid grey;

  & > :first-of-type {
    border-right: 1px solid grey;
  }

  & > * {
    flex: 1;
    text-align: center;
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-weight: 500;
  }
`;

const headerSectionStyles = css`
  padding: 1rem;
`;

const topSectionStyles = css`
  padding: 1rem;
  gap: 0.5rem;

  h1 {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    font-weight: 600;
  }

  h2 {
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-weight: 600;
  }
`;

const transfersSummaryPlayerContainerStyles = css`
  &:not(:last-of-type) {
    border-bottom: 1px solid grey;
  }
`;

const noTransfersStyles = css`
  font-weight: 700;
  text-align: center;
  font-size: clamp(1rem, 2vw, 1.5rem);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;
