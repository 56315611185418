import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  ManagerListModal,
  MiniLeagueCarousel,
  MostHurtfulTable,
} from "./components";
import {
  IMiniLeagueMostHurtfulRes,
  useGetMiniLeagueMostHurtfulTable,
} from "../../utils/hooks/useGetMiniLeagueMostHurtful";
import { GridEventListener } from "@mui/x-data-grid";
import { secondaryColor } from "../../config/theme";
import { Helmet } from "react-helmet";
import { MINI_LEAGUE_PAGE } from "../../constants/mini-league.constants";

interface IManagerList {
  ownedBy: IMiniLeagueMostHurtfulRes["owned_by"];
  playerName: IMiniLeagueMostHurtfulRes["name"];
}

/** Render the most hurtful payers */
export const MiniLeagueMostHurtfulPage = () => {
  const { mostHurtfulPlayerCardData } = useGetMiniLeagueMostHurtfulTable();
  const [managersList, setManagersList] = useState<null | IManagerList>(null);

  const handleOnExpandCellClick: GridEventListener<"rowClick"> = ({
    row,
  }: {
    row: IMiniLeagueMostHurtfulRes;
  }) => {
    setManagersList({ ownedBy: row.owned_by, playerName: row.name });
  };

  const handleModalClose = () => {
    setManagersList(null);
  };

  const handleCardBtnClick = (row: IMiniLeagueMostHurtfulRes) => {
    setManagersList({ ownedBy: row.owned_by, playerName: row.name });
  };

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{MINI_LEAGUE_PAGE.MOST_HURTFUL.META.TITLE}</title>
      </Helmet>
      {/* Modal */}
      {managersList && (
        <ManagerListModal
          open={managersList !== null}
          managersList={managersList.ownedBy}
          playerName={managersList.playerName}
          onClose={handleModalClose}
        />
      )}

      <Stack css={phasePlaceholderStyle}>
        <Typography variant="h1">Phase:</Typography>
        <Typography>Overall</Typography>
      </Stack>

      <MiniLeagueCarousel
        isMostHurtful={true}
        miniLeagueCardData={mostHurtfulPlayerCardData!}
        onBtnClick={handleCardBtnClick}
      />

      <MostHurtfulTable onRowClick={handleOnExpandCellClick} />
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const phasePlaceholderStyle = css`
  flex-direction: row;
  gap: 0.5rem;

  h1 {
    font-size: clamp(0.75rem, 3vw, 1rem);
    color: ${secondaryColor};
    line-height: 1;
  }

  P {
    font-size: clamp(0.75rem, 3vw, 1rem);
    line-height: 1;
  }
`;
