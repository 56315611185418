import { useEffect, useState } from "react";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { axiosInstance } from "../../config/axios.config";
import {
  IAllHighlights,
  IGlobalInfo,
  IHighlightCard,
  IHighlightsInfo,
  ISingleHighlightCard,
} from "../../utils/types/types";
import { HIGHLIGHT_IDS } from "../../constants/highlightsPage.constants";
import { useQuery } from "react-query";
import { rollbar, ROLLBAR_USER } from "../../config/rollbar.config";
import { isAxiosError } from "axios";
import { ENV_OPTIONS, SESSION_STORAGE } from "../../config/app.config";
import { useGetHighlightsTabs } from "./useGetHighlightsTabs";

/**
 * This hook is used to fetch the highlights data for the landing page
 * The hook uses the league id stored in session storage to fetch the highlights data
 * The hook will return an array of IHighlightCard objects, which will be filtered according to the highlights type
 * The hook will also handle errors, if the error is a too many requests error, the hook will log the error to rollbar
 * The hook will also return an isLoading boolean, which will be true if the data is being fetched
 */
export const useGetFastHighlightsForLandingPage = () => {
  const [highlightsWithCategory, setHighlightsWithCategory] = useState<
    ISingleHighlightCard[] | undefined
  >(undefined);
  const [tabsData] = useGetHighlightsTabs();

  const getHighlightsData = async () => {
    if (sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID)) {
      const { data: highlightsInfoData } = await axiosInstance.get(
        FPL_CHAMP_ENDPOINTS.HIGHLIGHTS_INFO()
      );

      const {
        data: { current_gw },
      } = await axiosInstance.get<IGlobalInfo>(
        FPL_CHAMP_ENDPOINTS.GLOBAL_INFO()
      );

      const fetchURL = FPL_CHAMP_ENDPOINTS.FAST_HIGHLIGHTS(
        current_gw,
        sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID)!
      );

      const { data: fastHighlights } = await axiosInstance.get(fetchURL);

      // Must loop over info to account for both views (TOP,BOTTOM)
      let newHighlightsCardData = (highlightsInfoData as IHighlightsInfo[])
        // Merge the highlights info with the all highlights data
        .map((info) => ({
          ...info,
          ...(fastHighlights as IAllHighlights[]).find(
            (highlight) => info.highlight_id === highlight.id
          ),
        }));

      // filter out league rank change if not live qw
      if (current_gw! === 1) {
        newHighlightsCardData = newHighlightsCardData.filter(
          (item) => item.highlight_id !== HIGHLIGHT_IDS.LEAGUE_RANK_CHANGE
        );
      }

      // Add the tabs data
      newHighlightsCardData = newHighlightsCardData.filter((item) => {
        return (
          !item.view_from_top_invalid &&
          !item.view_from_bottom_invalid &&
          item.details &&
          item.details.length !== 0
        );
      });

      return { newHighlightsCardData, current_gw } as {
        newHighlightsCardData: IHighlightCard[];
        current_gw: number;
      };
    }
  };

  const handleErrors = (error: any) => {
    const user = sessionStorage.getItem(ROLLBAR_USER)
      ? JSON.parse(sessionStorage.getItem(ROLLBAR_USER)!)
      : null;

    const errorData = {
      fullPath: window.location.href,
      pathname: window.location.pathname,
      errorMessage: error.message,
      errorName: error.name,
      errorStack: error.stack,
      ...user,
    };

    if (isAxiosError(error)) {
      if (error.response?.status === 429) {
        rollbar.log("Highlights Too Many Requests Errors", {
          ...errorData,
          errorDetailsFromBe: error.response?.data.error,
        });
      }
    } else {
      rollbar.log(error.message, {
        ...errorData,
      });
    }
  };

  const { data: fastHighlightsData, isLoading: isFastHighlightsLoading } =
    useQuery({
      useErrorBoundary: false, // by default we catch errors on ErrorBoundary Component
      onError:
        import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD
          ? handleErrors
          : undefined,
      queryFn: getHighlightsData,
      queryKey: [
        "useGetFastHighlightsForLandingPage",
        sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID),
      ],
    });

  useEffect(() => {
    if (fastHighlightsData && tabsData) {
      const highlightsWithCategory =
        fastHighlightsData.newHighlightsCardData.map((highlight) => {
          return {
            ...highlight,
            ...tabsData.find(
              (tab) => tab.category_id === highlight.category_id
            )!,
          };
        });

      setHighlightsWithCategory(highlightsWithCategory);
    }
  }, [fastHighlightsData, tabsData]);

  return {
    highlightsWithCategory,
    isLoading: isFastHighlightsLoading,
    liveGameWeek: fastHighlightsData?.current_gw,
  } as const;
};
