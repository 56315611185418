import { QUERY_PARAMS } from "../../config/routes";
import { useSearchParams } from "react-router-dom";
import {
  HIGHLIGHTS_PATH_NAME,
  SINGLE_HIGHLIGHTS_PATH_NAME,
} from "../regexExpressions";

/**
 * A custom hook that determines if the current URL contains a specific shared query parameter
 * and if the current page is considered sharable based on the pathname.
 *
 * @returns {Object} - An object containing two boolean values:
 *   - `isShared`: `true` if the `shared` query parameter exists in the URL and is set to `true`,
 *   - `isSharablePage`: `true` if the current pathname matches the sharable page patterns.
 */
export const useIsSharedURL = () => {
  const [searchParams] = useSearchParams();
  const isShared: boolean = searchParams.get(QUERY_PARAMS.SHARED)
    ? JSON.parse(searchParams.get(QUERY_PARAMS.SHARED)!) === true
    : false;

  const isSharablePage =
    HIGHLIGHTS_PATH_NAME.test(location.pathname) ||
    SINGLE_HIGHLIGHTS_PATH_NAME.test(location.pathname);

  return { isShared, isSharablePage };
};
