import { css } from "@emotion/react";
import {
  IPlayerOwnershipFixture,
  useGetPlayerOwnershipFixture,
} from "../../utils/hooks/useGetPlayerOwnershipFixture";
import { Stack, Typography } from "@mui/material";
import { AppTable, AppTableSkeleton } from "../../components";
import { playerOwnershipColumns } from "../../utils/tableColumns/fixturePlayerOwnershipColumns";
import { FIXTURES_RESULTS } from "../../constants/fixtures-pages.constants";
import { tableHeaderBgColor } from "../../config/theme";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../../config/routes";
import { GridEventListener } from "@mui/x-data-grid";
import { ManagerListModal } from "../mini-league-pages/components";
import { useState } from "react";

interface IManagerList {
  ownedBy: IPlayerOwnershipFixture["team_a_player"][0]["owned_by"];
  playerName: IPlayerOwnershipFixture["team_a_player"][0]["name"];
  isCaptain?: boolean;
}

/** Fetches and Renders the playerOwnerShip tables in the fixture results page */
export const FixtureOwnershipPage = () => {
  const { playerOwnership } = useGetPlayerOwnershipFixture();
  const [managersList, setManagersList] = useState<null | IManagerList>(null);

  const handleTableCellClick: GridEventListener<"cellClick"> = (params) => {
    const row: IPlayerOwnershipFixture["team_a_player"][0] = params.row;
    const field: keyof IPlayerOwnershipFixture["team_a_player"][0] =
      params.field as any;

    if (row.captain_count > 0 && field === "captain_count") {
      setManagersList({
        ownedBy: row.captained_by,
        playerName: row.name,
        isCaptain: true,
      });
    } else if (row.picked_count > 0 && field === "picked_count") {
      setManagersList({ ownedBy: row.owned_by, playerName: row.name });
    }
  };

  const handleModalClose = () => {
    setManagersList(null);
  };

  return (
    <Stack css={rootStyles}>
      {managersList && (
        <ManagerListModal
          showRank
          open={managersList !== null}
          managersList={managersList.ownedBy}
          playerName={managersList.playerName}
          onClose={handleModalClose}
          isCaptain={managersList.isCaptain}
        />
      )}

      <TeamTable
        isLoading={Boolean(playerOwnership)}
        teamName={`${playerOwnership?.team_h_name} ${FIXTURES_RESULTS.HOME_TEAM}`}
        tableRows={playerOwnership?.team_h_player}
        onCellClick={handleTableCellClick}
      />

      <TeamTable
        isLoading={Boolean(playerOwnership)}
        teamName={`${playerOwnership?.team_a_name} ${FIXTURES_RESULTS.AWAY_TEAM}`}
        tableRows={playerOwnership?.team_a_player}
        onCellClick={handleTableCellClick}
      />
    </Stack>
  );
};

/** Implements the player ownership table with default styles and settings */
const TeamTable = (props: {
  isLoading: boolean;
  teamName: string;
  tableRows: any[] | undefined;
  onCellClick: GridEventListener<"cellClick">;
}) => {
  const [searchParams] = useSearchParams();

  const columns =
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)! >=
    searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)!
      ? playerOwnershipColumns
      : playerOwnershipColumns.filter(
          (col) =>
            col.field !== "gameWeekPoints" && col.field !== "captain_count"
        );

  return (
    <Stack>
      <Typography css={tableHeaderStyles}>
        {props.isLoading && props.teamName}
      </Typography>
      {props.isLoading ? (
        <AppTable
          pageSize={10}
          getRowId={(row) => row.web_name}
          rows={props.tableRows}
          columns={columns}
          onCellClick={props.onCellClick}
        />
      ) : (
        <AppTableSkeleton />
      )}
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const tableHeaderStyles = css`
  background-color: ${tableHeaderBgColor};
  padding: 1rem;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 700;
  border-radius: 1rem 1rem 0 0;
`;
