import {
  homeCardBgGradient,
  mq,
  primaryGradient,
  transition_all,
} from "../../../config/styles";
import { css } from "@emotion/react";
import { Box, Stack, Typography } from "@mui/material";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import { LandingPageHeader } from "./LandingPageHeader";
import Swoosh from "../../../assets/landing-page/swoosh.svg";
import { bgColor, secondaryColor } from "../../../config/theme";
import { useState } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { SESSION_STORAGE } from "../../../config/app.config";
import { HighlightsDemoSectionStep1 } from "./HighlightsDemoSectionStep1";
import { HighlightsDemoSectionStep2 } from "./HighlightsDemoSectionStep2";
import { HighlightsDemoSectionStep3 } from "./HighlightsDemoSectionStep3";

/**
 * HighlightsDemoSection
 *
 * The main section of the landing page that guides the user to input their
 * FPL team and league, and then shows them the highlights.
 *
 * @remarks
 * The section is divided into two columns. The first column contains the steps
 * to follow, and the second column contains the actual form to input the
 * team and league details.
 *
 * The steps are:
 * 1. Input the team name or ID
 * 2. Select a league
 * 3. See the highlights
 *
 * Each step is a separate component that is rendered based on the current
 * step index. The step index is updated when the user clicks on a step.
 */
export const HighlightsDemoSection = () => {
  const [stepIndex, setStepIndex] = useState(0);

  const handleStepOne = async (values: {
    team_id: string;
    generalErr: string;
  }) => {
    sessionStorage.removeItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID);
    sessionStorage.setItem(
      SESSION_STORAGE.LANDING_PAGE_TEAM_ID,
      values.team_id
    );
    setStepIndex(1);
  };

  const handleStepTwo = ({ leagueId }: { leagueId: number | undefined }) => {
    if (!leagueId) return;
    sessionStorage.setItem(
      SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID,
      leagueId.toString()
    );
    setStepIndex(2);
  };

  const StepsComponents = [
    {
      comp: HighlightsDemoSectionStep1,
      handleNextClick: handleStepOne,
    },
    {
      comp: HighlightsDemoSectionStep2,
      handleNextClick: handleStepTwo,
      handlePrevClick: () => setStepIndex(0),
    },
    {
      comp: HighlightsDemoSectionStep3,
      handleNextClick: handleStepOne,
    },
  ];

  const handleStepClick = (index: number) => {
    if (
      index === 1 &&
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_TEAM_ID)
    ) {
      setStepIndex(index);
    } else if (
      index === 2 &&
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID) &&
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_TEAM_ID)
    ) {
      setStepIndex(index);
    } else if (index === 0) {
      setStepIndex(index);
    }
  };

  return (
    <Stack css={[rootStyles, stepIndex === 2 && pervertOverflowOnSection3]}>
      <Stack css={firstSectionContainerStyles}>
        <Stack>
          <Typography variant="h2" css={surTitleStyles}>
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.SURTITLE}
          </Typography>
          <LandingPageHeader>
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.TITLE.h1}
            <span css={focusTextStyles}>
              {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.TITLE.span}

              <img src={Swoosh} alt="swoosh" />
            </span>
          </LandingPageHeader>
        </Stack>

        <Stack css={stepsContainerStyles}>
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.STEPS.map(
            (step, index) => (
              <Stack
                key={index}
                onClick={() => handleStepClick(index)}
                css={[
                  stepContainerStyles,
                  index === stepIndex && currentStepStyles,
                ]}
              >
                <Box
                  css={[
                    stepIndexStyles,
                    index <= stepIndex ? primaryGradient : undefined,
                    index < stepIndex ? opacity60Styles : undefined,
                  ]}
                >
                  {index < stepIndex ? <CheckRoundedIcon /> : index + 1}
                </Box>

                <Stack>
                  <Typography variant="h3">{step.TITLE}</Typography>
                  <Typography variant="h4">{step.DESCRIPTION}</Typography>
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      </Stack>

      <Stack css={secondSectionContainerStyles}>
        {(() => {
          const {
            comp: Comp,
            handleNextClick,
            handlePrevClick,
          } = StepsComponents[stepIndex];

          return (
            <Comp
              onNextStepClick={handleNextClick as any}
              onPrevStepClick={handlePrevClick as any}
            />
          );
        })()}
      </Stack>
    </Stack>
  );
};

const pervertOverflowOnSection3 = css`
  overflow: hidden;
  isolation: isolate;
`;

const rootStyles = css`
  ${homeCardBgGradient}
  padding: 1rem;
  border-radius: 1rem;
  gap: 1rem;
  ${mq["md"]} {
    gap: 1.5rem;
    padding: 1.5rem;
    border-radius: 1.5rem;
    & > * {
      max-width: 100%;
    }
  }

  ${mq["lg"]} {
    gap: 2rem;
    padding: 2rem;
    min-height: 28rem;
    flex-direction: row;
    justify-content: space-between;
    & > * {
      width: 50%;
      max-width: 50%;
    }
  }
`;

const focusTextStyles = css`
  position: relative;
  img {
    position: absolute;
    top: 100%;
    left: 50%;
    translate: -50%;
    width: 90%;
    z-index: -1;
  }
`;

const surTitleStyles = css`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 500;
  color: ${secondaryColor};
`;

const firstSectionContainerStyles = css`
  gap: 2rem;
`;

const stepsContainerStyles = css`
  gap: 0.5rem;
`;

const stepContainerStyles = css`
  ${transition_all}
  cursor: pointer;
  padding: 1rem;
  min-height: 5rem;
  border-radius: 1.2rem;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
  }

  :hover {
    background-color: ${bgColor}55;
  }
`;

const currentStepStyles = css`
  background-color: ${bgColor};
  :hover {
    background-color: ${bgColor};
  }
`;

const stepIndexStyles = css`
  min-width: 2.5rem;
  aspect-ratio: 1/1;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;

  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 700;
  color: black;
  background-color: white;
`;

const secondSectionContainerStyles = css`
  justify-content: center;
  align-items: center;
`;

const opacity60Styles = css`
  opacity: 0.6;
`;
