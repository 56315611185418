import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppSimpleTable, AppSimpleTableSkeleton } from "../../../components";
import { IEplPlayerSummary } from "../../../utils/hooks/useEplPlayerSummary";
import { eplPlayerSummaryOwnedSince } from "../../../utils/tableColumns/eplPLayerSummaryColumns";
import { useGetEplPlayerOwnership } from "../../../utils/hooks/useGetEplPlayerOwnership";
import { useEffect, useState } from "react";
import { GridEventListener } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { IFplManager } from "../../../utils/types/types";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";

interface IEplPlayerFixtureSummaryTable {
  eplPlayerSummary: IEplPlayerSummary;
}

export interface IEplPlayerOwnedSince extends IFplManager {
  is_captain: boolean;
  owned_since: number;
}

/**
 * EplPlayerHistoryTable Component Displays current season fixtures for a player.
 */
export const EplPlayerSummaryOwnershipTable = (
  props: IEplPlayerFixtureSummaryTable
) => {
  const navigate = useNavigate();
  const { getTeamViewPath } = useGetUrl();
  const [tableRow, setTableRow] = useState<IEplPlayerOwnedSince[]>([]);
  const [managerFetchList, setManagerFetchList] = useState<number[]>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { eplPlayersSummaryOwnedSince } = useGetEplPlayerOwnership({
    fplManagers: managerFetchList,
    eplPlayerId: props.eplPlayerSummary.element,
  });

  useEffect(() => {
    if (eplPlayersSummaryOwnedSince) {
      const captainedByIds = props.eplPlayerSummary.captained_by.map(
        (fplManager) => fplManager.entry
      );
      const rows = props.eplPlayerSummary.owned_by.map((fplManager) => ({
        ...fplManager,
        is_captain: captainedByIds.includes(fplManager.entry),
        owned_since: eplPlayersSummaryOwnedSince[String(fplManager.entry)],
      }));
      setTableRow(rows);
    }
  }, [eplPlayersSummaryOwnedSince]);

  useEffect(() => {
    setManagerFetchList(
      [...props.eplPlayerSummary.owned_by.map((e) => e.entry)].splice(
        paginationModel.page * 10,
        10
      )
    );
  }, [paginationModel.page]);

  const handleTableCellClick: GridEventListener<"cellClick"> = (params) => {
    const row: IEplPlayerOwnedSince = params.row;
    const field = params.field as keyof IEplPlayerOwnedSince | "team";

    if (field === "team") {
      navigate(getTeamViewPath(row.entry));
    }
  };

  return (
    <Stack paddingInline={"1rem"}>
      <Typography css={titleStyles}>
        {EPL_PLAYER_SUMMARY.TABLE_TITLES.OWNERSHIP}
      </Typography>

      {tableRow.length ? (
        <AppSimpleTable
          getRowId={(row) => row.entry}
          rowHeight={40}
          columns={eplPlayerSummaryOwnedSince}
          rows={tableRow}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onCellClick={handleTableCellClick}
        />
      ) : (
        <AppSimpleTableSkeleton />
      )}
    </Stack>
  );
};

const titleStyles = css`
  font-family: Poppins;
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 700;
`;
