import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import mostHurtfulIcon from "../../../assets/mini-league/most-hurtful-players-icon.png";
import card4Image from "../../../assets/landing-page/card-4-img.png";
import { ExtraFeaturesCardContainer } from "./ExtraFeaturesCardContainer";
import { ExtraFeaturesIconContainer } from "./ExtraFeaturesIconContainer";

/**
 * A component that renders a card with a heading, subheading, and icon, specific
 * to the fourth card in the ExtraFeaturesSection of the landing page.
 */
export const ExtraFeaturesCard4 = () => {
  return (
    <ExtraFeaturesCardContainer>
      <Stack css={cardContentStyles}>
        <Stack
          css={css`
            flex: 1;
          `}
        >
          <Stack css={titlesStyles}>
            <Typography variant="h1">
              <ExtraFeaturesIconContainer icon={mostHurtfulIcon} />
              {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD4.TITLE}
            </Typography>

            <Typography variant="h3">
              {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD4.SUBTITLE}
            </Typography>
          </Stack>
        </Stack>
        <img src={card4Image} />
      </Stack>
    </ExtraFeaturesCardContainer>
  );
};

const cardContentStyles = css`
  height: 100%;
  padding: var(--spacing);
  gap: var(--spacing);
`;

const titlesStyles = css`
  flex: 1;
  gap: 0.5rem;

  h1 {
    display: flex;
    gap: 0.8rem;
    align-items: start;
    font-size: clamp(1.5rem, 3vw, 1.5rem);
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    font-weight: 700;
  }

  h3 {
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    color: #98a2b3;
  }
`;
