import { css } from "@emotion/react";
import { Button, CoolFeatureList } from ".";
import magicWand from "../assets/icons/magic-wand.svg";
import { IconButton, Modal, Stack, Typography } from "@mui/material";
import { SHOW_ME_COOL_BTN } from "../constants/global.constants";
import { homeCardBgGradient } from "../config/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../utils/google-analytics";

interface IShowMeSomethingCoolBtn {
  title: string;
  featuresList: {
    img: string;
    title: string;
    description: string;
    link?: string;
    handler?(): void;
    state?: {};
  }[];
}

/**
 * A button that opens a CoolFeatureList Modal
 * Renders  in the home page widgets
 */
export const ShowMeSomethingCoolBtn = (props: IShowMeSomethingCoolBtn) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleBtnClick = () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.SOMETHING_COOL_CLICKED);
    setIsOpen(true);
  };

  return (
    <>
      <Button button="solid" css={coolBtnStyles} onClick={handleBtnClick}>
        <Typography css={btnTextStyes}>{SHOW_ME_COOL_BTN.TITLE}</Typography>
        <img src={magicWand} />
      </Button>
      <Modal
        open={isOpen}
        css={modalRootStyles}
        onClose={() => setIsOpen(false)}
      >
        <Stack css={modalContainerStyles}>
          <Stack css={headerSectionStyles}>
            <Typography>{props.title}</Typography>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>

          <CoolFeatureList featuresList={props.featuresList} />
        </Stack>
      </Modal>
    </>
  );
};

const coolBtnStyles = css`
  height: 3rem;
`;

const btnTextStyes = css`
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 600;
  margin-right: 0.5rem;
`;

const modalRootStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const modalContainerStyles = css`
  ${homeCardBgGradient}
  padding: 1rem;
  border-radius: 1rem;
  max-width: 400px;
  margin: 1rem;
  width: calc(100%-2rem);
`;

const headerSectionStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  p {
    font-size: clamp(1.2rem, 3vw, 1.8rem);
    font-weight: 800;
    margin: 0.5rem;
  }

  button {
    color: white;
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
`;
