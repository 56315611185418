import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { axiosInstance } from "../../config/axios.config";
import {
  IAllHighlights,
  IHighlightCard,
  IHighlightsInfo,
} from "../../utils/types/types";
import { HIGHLIGHT_IDS } from "../../constants/highlightsPage.constants";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../constants/global.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { rollbar, ROLLBAR_USER } from "../../config/rollbar.config";
import { isAxiosError } from "axios";
import { ENV_OPTIONS } from "../../config/app.config";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";

/**
 * fetch the highlights data
 * @return [fullHighlightsData, highlightsCardData, isLoading, isError]
 */
export const useGetHighlights = () => {
  const [allHighlights, setAllHighlights] =
    useState<(IAllHighlights & IHighlightsInfo)[]>();
  const { liveGameWeek } = useGlobalInfoContext();
  const urlParams = useParams();
  const { isShared } = useIsSharedURL();
  const [searchParams] = useSearchParams();
  const { snackBarState, setSnackBarState, initialSnackBarState } =
    useSnackbarContext();
  const counter = useRef(0);

  const getHighlightsData = async (highLightsType: "slow" | "fast") => {
    if (
      urlParams[PATH_PARAMS.LEAGUE_ID] &&
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)
    ) {
      const { data: highlightsInfoData } = await axiosInstance.get(
        FPL_CHAMP_ENDPOINTS.HIGHLIGHTS_INFO(isShared)
      );

      const fetchURL =
        highLightsType === "slow"
          ? FPL_CHAMP_ENDPOINTS.SLOW_HIGHLIGHTS(
              searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
              urlParams[PATH_PARAMS.LEAGUE_ID]!,
              isShared
            )
          : FPL_CHAMP_ENDPOINTS.FAST_HIGHLIGHTS(
              searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
              urlParams[PATH_PARAMS.LEAGUE_ID]!,
              isShared
            );

      const { data: fastHighlights } = await axiosInstance.get(fetchURL);

      // Must loop over info to account for both views (TOP,BOTTOM)
      let newHighlightsCardData = (highlightsInfoData as IHighlightsInfo[])
        // Merge the highlights info with the all highlights data
        .map((info) => ({
          ...info,
          ...(fastHighlights as IAllHighlights[]).find(
            (highlight) => info.highlight_id === highlight.id
          ),
        }))
        .filter(
          // filter out singleHighlightsCardData that doesn't have a details array
          (singleHighlightsCardData) =>
            highLightsType === "slow" ? singleHighlightsCardData.details : true
        );

      // filter out league rank change if not live qw
      if (
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)! !==
        String(liveGameWeek)
      ) {
        newHighlightsCardData = newHighlightsCardData.filter(
          (item) => item.highlight_id !== HIGHLIGHT_IDS.LEAGUE_RANK_CHANGE
        );
      }

      return newHighlightsCardData as IHighlightCard[];
    }
  };

  const handleErrors = (error: any) => {
    const user = sessionStorage.getItem(ROLLBAR_USER)
      ? JSON.parse(sessionStorage.getItem(ROLLBAR_USER)!)
      : null;

    const errorData = {
      fullPath: window.location.href,
      pathname: window.location.pathname,
      searchParams: window.location.search,
      errorMessage: error.message,
      errorName: error.name,
      errorStack: error.stack,
      ...user,
    };

    if (isAxiosError(error)) {
      if (error.response?.status === 429) {
        rollbar.log("Highlights Too Many Requests Errors", {
          ...errorData,
          errorDetailsFromBe: error.response?.data.error,
        });
      }
    } else {
      rollbar.log(error.message, {
        ...errorData,
      });
    }
  };

  const {
    data: slowHighlightsData,
    isLoading: isSlowHighlightsLoading,
    isError,
  } = useQuery({
    useErrorBoundary: false, // by default we catch errors on ErrorBoundary Component
    onError:
      import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD
        ? handleErrors
        : undefined,
    queryFn: () => getHighlightsData("slow"),
    queryKey: [
      "useGetSlowHighlights",
      urlParams[PATH_PARAMS.LEAGUE_ID],
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
    ],
  });

  const { data: fastHighlightsData, isLoading: isFastHighlightsLoading } =
    useQuery({
      useErrorBoundary: false, // by default we catch errors on ErrorBoundary Component
      onError:
        import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD
          ? handleErrors
          : undefined,
      queryFn: () => getHighlightsData("fast"),
      queryKey: [
        "useGetFastHighlights",
        urlParams[PATH_PARAMS.LEAGUE_ID],
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
      ],
    });

  useEffect(() => {
    if (fastHighlightsData && slowHighlightsData) {
      setAllHighlights(
        [
          ...fastHighlightsData.filter((h) => h.details),
          ...slowHighlightsData,
        ].sort((a, b) => a.highlight_order - b.highlight_order)
      );
    } else if (fastHighlightsData) {
      setAllHighlights(
        fastHighlightsData.sort((a, b) => a.highlight_order - b.highlight_order)
      );
    }
  }, [fastHighlightsData, slowHighlightsData]);

  // filter out "Transfers Outcome" and "Transfers" if gameWeek is 1
  let cardsHighlightsData =
    allHighlights &&
    (searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)! === "1"
      ? allHighlights.filter(
          (item) =>
            item.highlight_id !== HIGHLIGHT_IDS.TRANSFERS_OUTCOME &&
            item.highlight_id !== HIGHLIGHT_IDS.TRANSFERS
        )
      : allHighlights);

  useEffect(() => {
    const counterInterval = setInterval(() => {
      if (isSlowHighlightsLoading) {
        counter.current += 1;
        if (counter.current === 8) {
          setSnackBarState(() => ({
            ...snackBarState,
            isOpen: true,
            message: SNACKBAR_MESSAGES.HIGHLIGHTS_LOADING_8_SEC,
            duration: 7500,
          }));
        } else if (counter.current === 12) {
          setSnackBarState(() => ({
            ...snackBarState,
            isOpen: true,
            message: SNACKBAR_MESSAGES.HIGHLIGHTS_LOADING_12_SEC,
            duration: 100 * 1000,
          }));
        }
      }
    }, 1000);

    if (!isSlowHighlightsLoading) {
      counter.current = 0;
      clearInterval(counterInterval);
      setSnackBarState(() => ({ ...snackBarState, isOpen: false }));
    }

    return () => {
      setSnackBarState(initialSnackBarState);
      clearInterval(counterInterval);
    };
  }, [isSlowHighlightsLoading]);

  return {
    fullHighlightsData: allHighlights,
    cardsHighlightsData,
    isLoading: isFastHighlightsLoading,
    isError,
  } as const;
};
