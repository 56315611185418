import { css } from "@emotion/react";
import { Box, Slide, Stack, Typography } from "@mui/material";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../config/routes";
import { mainNavigationHeight } from "../config/theme";
import { mq } from "../config/styles";
import { FLOATING_SIGN_UP } from "../constants/global.constants";

/**
 * Display a message encouraging the user to sign up
 * This is renders when the user is not signed in and is viewings a shared page
 */
export const FloatingSignUp = () => {
  return (
    <Slide appear in direction="up" mountOnEnter>
      <Stack css={rootStyles}>
        <Typography css={titleStyles}>{FLOATING_SIGN_UP.title}</Typography>
        <Box>
          <Button LinkComponent={Link} to={ROUTES.REGISTER_PAGE} button="CTA">
            {FLOATING_SIGN_UP.BTN}
          </Button>
        </Box>
      </Stack>
    </Slide>
  );
};

const rootStyles = css`
  position: absolute;
  z-index: 1;
  bottom: calc(${mainNavigationHeight} + 1rem);
  left: 50%;
  translate: -50% 0;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 2rem);
  max-width: 600px;

  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  color: black;
  box-shadow: 0 5px 25px #26252562;

  ${mq["sm"]} {
    bottom: 1.5rem;
  }
`;

const titleStyles = css`
  flex: 1;
  font-weight: 500;
`;
