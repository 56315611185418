import { useEffect } from "react";
import { QUERY_PARAMS, ROUTES } from "../config/routes";
import { useUserContext } from "../context/UserContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useIsSharedURL } from "../utils/hooks/useIsSharedURL";

/**
 *  This component acts as a gatekeeper, guarding specific routes within the application.
 *  It ensures that only authorized users with the necessary `teamId` can access those routes.
 *
 * @param {object} props - Contains information about what to protect.
 * @param {React.ReactNode} props.children - The content to be displayed if access is granted.
 * @returns {React.ReactNode} - Returns either the protected content or redirects to a login or onboarding page.
 */

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isShared, isSharablePage } = useIsSharedURL();

  useEffect(() => {
    // Not a user but visited with a shared link
    if (user === null && isSharablePage && isShared) {
      return;
    }

    // A logged in User visited with a shared link, remove share query param
    if (user && isShared) {
      searchParams.delete(QUERY_PARAMS.SHARED);
      setSearchParams(searchParams, { replace: true });
    }

    // push location (route) to router state
    if (user === null) {
      // null user is user not signed in
      navigate(ROUTES.INTRO_PAGE, {
        state: { from: location },
        replace: true,
      });
    } else if (user && !user.teamId) {
      // push location (route) to router state
      navigate(ROUTES.TEAM_ID_PAGE, {
        state: { from: location },
        replace: true,
      });
    }
  }, [user]);

  return <>{children}</>;
};
