import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import logo from "../../../assets/identity/get-started-fpl-logo.svg";
import { Button, CoolFeatureList } from "../../../components";
import { Helmet } from "react-helmet";
import {
  GET_STARTED_PAGES,
  INTRO_PAGE_CONTENT,
} from "../../../constants/auth-pages.constants";
import { ROUTES } from "../../../config/routes";
import { bgColor } from "../../../config/theme";
import { mq, primaryGradient } from "../../../config/styles";

import { FormContainer } from "../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import { useState } from "react";
import { signInAnonymousUser } from "../utils/authHelpers";

/** Renders the Get started page for user that are not signed in and came from a shared link */
export const IntroView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetSTartedBtnClick = async () => {
    setIsLoading(true);
    if (!user) {
      const anonymsUser = await signInAnonymousUser();
      setUser(anonymsUser);
    }

    navigate(ROUTES.TEAM_ID_PAGE, {
      state: location.state,
    });
    setIsLoading(false);
  };
  return (
    <FormContainer>
      <Helmet>
        <title>{INTRO_PAGE_CONTENT.META_DATA.TITLE}</title>
      </Helmet>

      <Stack css={rootStyles}>
        <Stack css={mainContainerStyles}>
          <Stack css={logoContainerStyles}>
            <img src={logo} css={imgStyles} />
            <Typography css={logoTextStyles}>FPL Champ</Typography>
          </Stack>
          <Typography css={titleStyles}>{INTRO_PAGE_CONTENT.TITLE}</Typography>

          <CoolFeatureList featuresList={INTRO_PAGE_CONTENT.FEATURES} />
          <Typography css={subTitleStyles}>
            <span css={textOverLay}>{GET_STARTED_PAGES.SUBTITLE}</span>
            <span>{GET_STARTED_PAGES.EMOJI}</span>
          </Typography>
        </Stack>

        <Button
          to={ROUTES.REGISTER_PAGE}
          state={location.state}
          button="gradient"
        >
          {INTRO_PAGE_CONTENT.REGISTER_OR_LOGIN_BUTTON}
        </Button>

        <Button
          button="outline-gradient"
          disabled={isLoading}
          onClick={handleGetSTartedBtnClick}
        >
          {INTRO_PAGE_CONTENT.VISITOR_MODE_BTN}
        </Button>
      </Stack>
    </FormContainer>
  );
};

const rootStyles = css`
  height: 100svh;
  width: 100%;
  padding: 2rem;
  background-color: ${bgColor};
  gap: 1rem;

  ${mq["md"]} {
    padding: 0;
    height: auto;
    background-color: transparent;
    gap: 1rem;
  }
`;

const imgStyles = css`
  width: 6.5rem;
`;

const mainContainerStyles = css`
  flex: 1;
  gap: 1rem;
`;

const logoContainerStyles = css`
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const logoTextStyles = css`
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 900;
  text-transform: uppercase;
`;

const titleStyles = css`
  font-weight: 600;
  font-size: clamp(1.3rem, 2.2vw, 1.8rem);
  text-align: center;
`;

const subTitleStyles = css`
  font-weight: 700;
  font-size: 1.3rem;
`;

const textOverLay = css`
  ${primaryGradient}
  background-clip: text;
  color: transparent;
`;
