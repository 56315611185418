import { Button } from "../../../components";
import { supabase } from "../../../config/supabase";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { animatePulse } from "../../../config/styles";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";
import { useUserContext } from "../../../context/UserContext";
import {
  addTeamIdSupabase,
  signInUserWithProvider,
  signOutSupabase,
} from "../utils/authHelpers";
import { Provider } from "@supabase/supabase-js";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";

interface IOAuthButton {
  provider: Provider;
  children: React.ReactNode | string;
}

/** OAuth button that signing in the user with oAuth and redirect to the team id page */
export const OAuthButton = ({ provider, children }: IOAuthButton) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { user, setUser } = useUserContext();
  const { setLeagues } = useGlobalInfoContext();

  const [searchParams] = useSearchParams();

  const handleOAuthButton = async () => {
    TrackGoogleAnalyticsEvent(GA4_EVENTS.SIGN_UP_GOOGLE_CLICKED);
    setIsLoading(true);

    if (location.state && location.state.from) {
      sessionStorage.setItem(
        SESSION_STORAGE.URL_HISTORY,
        location.state.from.pathname + location.state.from.search
      );
    }

    // Link identity to anonyms user
    if (user && user.isAnonymous) {
      // add team id to anonyms user
      if (sessionStorage.getItem(SESSION_STORAGE.ANON_USER_TEAM_ID)) {
        await addTeamIdSupabase({
          team_id: Number(
            sessionStorage.getItem(SESSION_STORAGE.ANON_USER_TEAM_ID)!
          ),
        });
        sessionStorage.removeItem(SESSION_STORAGE.ANON_USER_TEAM_ID);
      }

      const { error } = await supabase.auth.linkIdentity({
        provider,
        options: {
          redirectTo: `${window.location.origin + window.location.pathname}`,
        },
      });

      if (error) {
        throw error;
      }
    } else {
      // Sign up user
      signInUserWithProvider(provider);
    }

    setIsLoading(false);
  };
  //"/register

  useEffect(() => {
    // if user exist in supabase already we get ?error=server_error&error_code=422&error_description=Identity+is+already+linked+to+another+user"
    // this from the login attempt
    if (
      user &&
      user.isAnonymous &&
      searchParams.get("error") &&
      searchParams.get("error_code") === "422"
    ) {
      (async () => {
        // logout of anamus user
        await signOutSupabase();
        setUser(undefined);
        setLeagues([]);
        // sign in uth Oauth
        await signInUserWithProvider(provider);
      })();
    }
  }, [searchParams]);

  return (
    <Button
      css={isLoading ? loadingStyle : undefined}
      onClick={handleOAuthButton}
      button="google"
      disabled={isLoading}
    >
      {children}
    </Button>
  );
};

const loadingStyle = css`
  ${animatePulse}
  background-color: white  !important;
`;
