import { css } from "@emotion/react";
import card6Image from "../../../assets/landing-page/card-6-img.svg";
import { Stack, Typography } from "@mui/material";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import { Button } from "../../../components";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { mq } from "../../../config/styles";
import { ROUTES } from "../../../config/routes";

/**
 * A component that renders a card with a heading and a button, specific
 * to the sixth card in the ExtraFeaturesSection of the landing page.
 * It is a call-to-action for the user to proceed to the intro page.
 */
export const ExtraFeaturesCard6 = () => {
  return (
    <Stack css={cardContainerStyles}>
      <img src={card6Image} />
      <Stack css={cardContentContainer}>
        <Typography variant="h1">
          {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD6.TITLE}
        </Typography>

        <Button to={ROUTES.INTRO_PAGE} button="gradient" css={buttonStyles}>
          {LANDING_PAGE_CONTENT.EXTRA_FEATURES_SECTION.CARD6.BTN}
          <EastRoundedIcon />
        </Button>
      </Stack>
    </Stack>
  );
};

const cardContainerStyles = css`
  border-radius: 1.5rem;
  overflow: hidden;
  padding: var(--spacing);
  position: relative;
  isolation: isolate;
  min-height: 20rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

const cardContentContainer = css`
  position: relative;
  height: 100%;
  h1 {
    position: absolute;
    top: 30%;
    font-size: clamp(1.8rem, 3vw, 2.2rem);
    font-weight: 600;
  }
`;

const buttonStyles = css`
  position: absolute;
  bottom: 0;
  width: fit-content;
  color: white;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 500;
  height: auto;
  padding: 0.6rem 2rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: inherit;
    font-size: inherit;
    /* width: clamp(1rem, 2.5vw, 1.5rem); */
  }

  ${mq["lg"]} {
    padding: 0.8rem 2rem;
  }
`;
