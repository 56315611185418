import { Helmet } from "react-helmet";
import { Link, AppLogoDivider } from "../../../components";
import { Typography } from "@mui/material";
import { css } from "@emotion/react";
import { container, mq } from "../../../config/styles";
import { RegisterForm } from "./RegisterForm";
import { REGISTER_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { ROUTES } from "../../../config/routes";
import { Header, FormContainer, OAuthButton } from "../components";
import { OAUTH_PROVIDERS } from "../../../config/OAuthProviders.constants";
import { useLocation } from "react-router-dom";

/** Renders the register page  */
export const RegisterPage = () => {
  const location = useLocation();
  return (
    <FormContainer>
      <Helmet>
        <script>
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': '${
                    import.meta.env.VITE_GOOGLE_ADS_RESISTER_CONVERSION_KEY
                  }',
                  'event_callback': callback
              });
              return false;
            }
          `}
        </script>
      </Helmet>
      <main css={root}>
        <Helmet>
          <title>{REGISTER_PAGE_CONTENT.META.TITLE}</title>
        </Helmet>
        <Header
          title={REGISTER_PAGE_CONTENT.TITLE}
          subtitle={REGISTER_PAGE_CONTENT.SUBTITLE}
        />

        <OAuthButton provider={OAUTH_PROVIDERS.GOOGLE}>
          {REGISTER_PAGE_CONTENT.GOOGLE_BUTTON_TEXT}
        </OAuthButton>

        <AppLogoDivider />

        <RegisterForm />

        <Typography>
          Do you have account?{" "}
          <Link to={ROUTES.SIGN_IN_PAGE} state={location.state}>
            Sign In
          </Link>
        </Typography>
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;
