import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import arrowGradient from "../assets/icons/arrow-gradient.svg";
import { Link } from "react-router-dom";
import { primaryGradient } from "../config/styles";

interface ICoolFeatureList {
  featuresList: {
    img: string;
    title: string;
    description: string;
    link?: string;
    handler?(): void;
    state?: {};
  }[];
}

/** Displays a list of features with images, titles, descriptions, and optional links or handlers.*/
export const CoolFeatureList = (props: ICoolFeatureList) => {
  return (
    <Stack component={"ul"} css={featuresContainerStyles}>
      {props.featuresList.map((item, index) => {
        const Comp = item.link ? Link : "span";
        return (
          <li key={index}>
            <Comp
              to={item.link ? item.link : ""}
              css={coolFeatContainerStyles}
              state={item.state}
              onClick={item.handler}
            >
              <img src={item.img} />
              <span css={coolFeatTitleStyles}>{item.title}</span>
              <img src={arrowGradient} />
            </Comp>
            <Typography>{item.description}</Typography>
          </li>
        );
      })}
    </Stack>
  );
};

const featuresContainerStyles = css`
  gap: 1rem;
`;
const coolFeatContainerStyles = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const coolFeatTitleStyles = css`
  ${primaryGradient}
  background-clip: text;
  color: transparent;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  font-weight: 600;
`;
