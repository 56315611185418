import { Helmet } from "react-helmet";
import { Stack } from "@mui/material";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { IHighlightCard, TCategoryId } from "../../utils/types/types";
import allHighlightsIcon from "../../assets/highlights/all-hl.png";
import { HIGHLIGHTS_PAGE_CONTENT } from "../../constants/highlightsPage.constants";
import { HighlightsCardSkeleton, HighlightsCard } from "./components";
import { useParams } from "react-router";
import { PATH_PARAMS } from "../../config/routes";
import { atom } from "jotai";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import {
  Carousel,
  PageHeader,
  SelectGameWeekDropDown,
  AppTabs,
  HighlightsErrorCard,
  AppShareButton,
} from "../../components";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import {
  ICategory,
  useGetHighlightsTabs,
} from "../../utils/hooks/useGetHighlightsTabs";
import { APP_TOOLTIP_CONSTANTS } from "../../constants/global.constants";
import { useSearchParams } from "react-router-dom";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useIsSharedURL } from "../../utils/hooks/useIsSharedURL";
import { useGetHighlights } from "../../utils/hooks/useGetHighlights";

const ALL_TAB_VALUE: ICategory = {
  _id: "000",
  category_display_name: "All",
  category_id: "all",
  category_icon: allHighlightsIcon,
  category_order: 0,
};

const highlightCarouselAtoms = Array.from({ length: 3 }, () => atom(0));

/** Renders the highlights page */
export const HighlightsPage = () => {
  const { isShared } = useIsSharedURL();
  const [searchParams] = useSearchParams();
  const { selectedLeague } = useGlobalInfoContext();
  const [tabs, setTabs] = useState<ICategory[]>([ALL_TAB_VALUE]);
  const [selectedTab, setSelectedTab] = useState<ICategory>(ALL_TAB_VALUE);
  const [selectedTabCards, setSelectedTabCards] = useState<IHighlightCard[]>(
    []
  );

  const { cardsHighlightsData, isError, isLoading } = useGetHighlights();
  const [tabsData] = useGetHighlightsTabs();

  const {
    getSingleHighlightsPath,
    getHighlightsPath,
    getHighlightsCategoryPath,
  } = useGetUrl();
  const params = useParams();

  useEffect(() => {
    const getSelectedTab = () => {
      if (!tabsData) return ALL_TAB_VALUE;

      const selectedTab = tabsData.find(
        (tab) => tab.category_id === params[PATH_PARAMS.CATEGORY_ID]
      );
      return selectedTab || ALL_TAB_VALUE;
    };

    setSelectedTab(getSelectedTab());
  }, [params]);

  useEffect(() => {
    if (tabsData) {
      setTabs(
        [ALL_TAB_VALUE, ...tabsData].sort(
          (a, b) => a.category_order - b.category_order
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData]);

  useEffect(() => {
    if (
      cardsHighlightsData &&
      selectedTab.category_id !== ALL_TAB_VALUE.category_id
    ) {
      setSelectedTabCards(
        cardsHighlightsData
          .filter((info) => info.category_id === selectedTab.category_id)
          .sort((a, b) => a.highlight_order - b.highlight_order)
      );
    }
  }, [selectedTab, cardsHighlightsData]);

  const navigate = useNavigateState();

  const selectTab = (categoryId: TCategoryId) => {
    const tab = tabs.find((tab) => tab.category_id === categoryId);
    if (tab) {
      setSelectedTab(tab);
      // update url to reflect the selected tab
      if (tab.category_id === ALL_TAB_VALUE.category_id) {
        navigate(getHighlightsPath());
      } else {
        navigate(getHighlightsCategoryPath(tab.category_id));
      }
    }
  };

  const handleTabsChange = (_: any, categoryId: TCategoryId) => {
    selectTab(categoryId);
  };

  const handleChipClick = (
    categoryId: TCategoryId,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    selectTab(categoryId);
  };

  return (
    <Stack gap={3}>
      <Helmet>
        <title>{HIGHLIGHTS_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>

      <PageHeader title={HIGHLIGHTS_PAGE_CONTENT.TITLE} />

      <Stack css={gwAndShareContainer}>
        <SelectGameWeekDropDown />
        {cardsHighlightsData && !isShared && (
          <AppShareButton
            message={`Check all sort of cool highlights for GW ${searchParams.get(
              PATH_PARAMS.SELECTED_GAME_WEEK
            )} for the "${selectedLeague?.name}" league through FPL CHAMP!`}
          />
        )}
      </Stack>

      <AppTabs
        handleTabsChange={handleTabsChange}
        value={selectedTab.category_id}
        tabs={tabs.map((tab) => ({
          label: tab.category_display_name,
          value: tab.category_id,
          img: tab.category_icon,
          toolTipTitle:
            APP_TOOLTIP_CONSTANTS.MESSAGES.HIGHLIGHTS[tab.category_id].TITLE,
          toolTipSubTitle:
            APP_TOOLTIP_CONSTANTS.MESSAGES.HIGHLIGHTS[tab.category_id].SUBTITLE,
        }))}
      />

      {isError && <HighlightsErrorCard />}

      {!isError && (
        <>
          {selectedTab.category_id === ALL_TAB_VALUE.category_id ? (
            <>
              {tabs
                .filter((tab) => tab.category_id !== ALL_TAB_VALUE.category_id)
                .map((tab, i) => (
                  <Carousel
                    key={tab.category_id}
                    carouselAtom={highlightCarouselAtoms[i]}
                  >
                    {cardsHighlightsData && !isLoading
                      ? cardsHighlightsData
                          .filter(
                            (info) => info.category_id === tab.category_id
                          )
                          .sort((a, b) => a.highlight_order - b.highlight_order)
                          .map((info) => (
                            <HighlightsCard
                              key={`${info.category_id}${info.highlight_id}`}
                              toHighlightURL={getSingleHighlightsPath(
                                info.category_id,
                                info.highlight_id
                              )}
                              cardStyles={carouselCardsStyle}
                              accentColor={tab.accent_color!}
                              chipImageURL={tab.category_icon}
                              onChipClick={handleChipClick}
                              highlightCardData={info}
                            />
                          ))
                      : Array.from({ length: 15 }).map((_, index) => (
                          <HighlightsCardSkeleton key={index} />
                        ))}
                  </Carousel>
                ))}
            </>
          ) : (
            <>
              {cardsHighlightsData && !isLoading ? (
                <Stack gap={3}>
                  {selectedTabCards.map((info) => (
                    <HighlightsCard
                      key={`${info._id}`}
                      toHighlightURL={getSingleHighlightsPath(
                        info.category_id,
                        info.highlight_id
                      )}
                      accentColor={selectedTab.accent_color}
                      highlightCardData={info}
                    />
                  ))}
                </Stack>
              ) : (
                <HighlightsCardSkeleton />
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

const carouselCardsStyle = css`
  height: 100%;
`;

const gwAndShareContainer = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
