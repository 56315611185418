import { css } from "@emotion/react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Skeleton } from "../../../components";
import { EPL_PLAYER_SUMMARY } from "../../../constants/view-team-pages.constants";
import { ITeamInfo } from "../../../utils/hooks/useGetTeamInfo";

interface IEplStatsTable {
  stats: ITeamInfo["picks"][0]["explain"][0]["stats"];
}

/** Renders a simple table for the player summary stats displayed in the PLayer summary modal */
export const EplStatsTable = (props: IEplStatsTable) => {
  return (
    <Stack>
      <TableContainer>
        <Table css={tableStyles}>
          <TableHead>
            <TableRow css={tableHeadStyles}>
              <TableCell>{EPL_PLAYER_SUMMARY.STATS_TABLE.ROW_HEAD}</TableCell>
              <TableCell align="center">
                {EPL_PLAYER_SUMMARY.STATS_TABLE.VALUE}
              </TableCell>
              <TableCell align="center">
                {EPL_PLAYER_SUMMARY.STATS_TABLE.POINTS}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.stats ? (
              Object.values(props.stats).map((row) => (
                <TableRow key={row.stat_name} css={tableRowStyles}>
                  <TableCell component="th" scope="row">
                    {row.stat_name}
                  </TableCell>
                  <TableCell align="center">{row.value}</TableCell>
                  <TableCell align="center">{row.points}</TableCell>
                </TableRow>
              ))
            ) : (
              <StatsLoadingSkeleton />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const StatsLoadingSkeleton = () =>
  Array.from({ length: 3 }, (_, i) => (
    <TableRow
      key={i}
      css={css`
        * {
          padding: 0.5rem 0.5rem 0.5rem 0 !important;
          flex: 1;
          * {
            background-color: #1c21307b;
          }
        }
      `}
    >
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  ));

const tableStyles = css`
  * {
    border: none !important;
    padding: 0.8rem 0 !important;
  }
`;

const tableHeadStyles = css`
  & > * {
    color: white !important;
    font-size: clamp(1.1rem, 2vw, 1.5rem) !important;
    font-weight: 500 !important;
  }
`;

const tableRowStyles = css`
  &:last-of-type {
    border-top: 1px #e1dfdf64 solid !important;
    & > * {
      font-size: clamp(1rem, 2vw, 1.2rem) !important;
      font-weight: 500 !important;
    }
  }
  & > * {
    color: #dddedf !important;
    font-size: clamp(0.875rem, 1.8vw, 1rem) !important;
  }
`;
